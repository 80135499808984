export default function useRangeFormat() {
  return {
    formatRangePhone(first: any, last: any) {
      return first !== undefined &&
        first !== null &&
        last !== undefined &&
        last !== null
        ? `${first}-${last}`
        : "";
    },
  };
}
