import { axiosInstance as axios } from "./interceptor";
// import axios from "axios";
import { getURLFunc } from "./apiUtils";
import { SearchRequestParams } from "@/interfaces/SearchRequestParams";
import { SearchResult } from "./interfaces/SearchResult";

export enum LogicalOperator {
  And = "And",
  Or = "Or",
}

export enum Sex {
  Female = "F",
  Male = "M",
}

export interface PresetItem {
  id: string;
  name: string;
  score_mmse_from?: number;
  score_mmse_to?: number;
  score_adascog_from?: number;
  score_adascog_to?: number;
  operator_type?: LogicalOperator;
  age_from?: number;
  age_to?: number;
  sex_types?: Sex[];
}

const getUrl = (siteId: string, url: string) =>
  getURLFunc(`/study/api/sites/${siteId}/presets`)(url);

export function createPreset(siteId: string, preset: PresetItem) {
  return axios.post<string>(getUrl(siteId, ""), preset).then((r) => r.data);
}

export function getPresetById(siteId: string, presetId: string) {
  return axios
    .get<PresetItem>(getUrl(siteId, `/${presetId}`))
    .then((r) => r.data);
}

export function editPreset(siteId: string, preset: PresetItem) {
  return axios
    .put<PresetItem>(getUrl(siteId, `/${preset.id}`), preset)
    .then((r) => r.data);
}

export function deletePreset(siteId: string, preset: PresetItem) {
  return axios
    .delete<PresetItem>(getUrl(siteId, `/${preset.id}`))
    .then((r) => r.data);
}

export function searchPresets(params: SearchRequestParams, siteId: string) {
  return axios
    .post<SearchResult<PresetItem>>(
      // api/sites/{siteId:guid}/presets/search

      getUrl(siteId, "/search"),
      params
    )
    .then((r) => r.data);
}

export interface PresetFilterItem {
  id: string;
  name: string;
  site_id: string;
}

export function presetFilters(params: SearchRequestParams) {
  return axios
    .post<SearchResult<PresetFilterItem>>(
      getURLFunc(`/study/api/presets`)("/filters"),
      params
    )
    .then((r) => r.data);
}
