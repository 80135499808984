import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "table-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownSmallIcon = _resolveComponent("ChevronDownSmallIcon")
  const _component_FilterIcon = _resolveComponent("FilterIcon")
  const _component_RangeFilter = _resolveComponent("RangeFilter")
  const _component_HeaderFilter = _resolveComponent("HeaderFilter")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("span", null, _toDisplayString(_ctx.header), 1),
    (_ctx.sortable)
      ? (_openBlock(), _createBlock(_component_ChevronDownSmallIcon, {
          key: 0,
          class: { 'sorting-active': _ctx.sortingActive, 'sorting-desc': _ctx.sortingDesc },
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeSorting()))
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.filterable)
      ? (_openBlock(), _createBlock(_component_el_dropdown, {
          key: 1,
          ref: "dropdown",
          class: ["el-dropdown", { 'filter-filled': _ctx.isFilterFilled }],
          trigger: "click"
        }, {
          dropdown: _withCtx(() => [
            _createVNode(_component_el_dropdown_menu, { "hide-on-click": false }, {
              default: _withCtx(() => [
                (_ctx.rangeData)
                  ? (_openBlock(), _createBlock(_component_RangeFilter, {
                      key: 0,
                      filterData: _ctx.rangeData,
                      onCloseDropdown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDropdown()))
                    }, null, 8, ["filterData"]))
                  : (_openBlock(), _createBlock(_component_HeaderFilter, {
                      key: 1,
                      filterData: _ctx.filterData
                    }, null, 8, ["filterData"]))
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_FilterIcon)
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true)
  ]))
}