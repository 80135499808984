import { computed, defineComponent, reactive, ref, watch } from "vue";

import { showError, showErrorOptional } from "@/utils/notificationUtils";
import { createPreset, editPreset, PresetItem } from "@/api/SitePresetsApi";
import {
  PRESET_NAME_REQUIRED_MESSAGE,
  TO_BIGGER_THAN_FROM_MESSAGE,
} from "@/utils/validationMessages";
import {
  checkIsBetween,
  checkIsNumber,
  hasValue,
} from "@/utils/validationUtils";
import useFormUtils from "@/uses/useFormUtils";

export default defineComponent({
  name: "PresetForm",
  props: {
    siteId: String,
    editModel: { default: null, type: Object as () => PresetItem | null },
  },
  components: {},
  setup(props, { emit }) {
    const isEdit = computed(() => props.editModel);

    const age = isEdit.value
      ? [props.editModel?.age_from, props.editModel?.age_to]
      : ["18", "120"];
    const form = reactive({
      name: "",
      score_mmse_from: null,
      score_mmse_to: null,
      score_adascog_from: null,
      score_adascog_to: null,
      operator_type: null,
      age_from: null,
      age_to: null,
      age,
      sex_types: [],
      ...props.editModel,
    });
    if ((form.operator_type as any) === 0) {
      form.operator_type = null;
    }

    const rules = reactive({
      name: [
        {
          required: true,
          message: PRESET_NAME_REQUIRED_MESSAGE,
          trigger: "blur",
        },
      ],
      score_mmse_from: [
        {
          message: "Please enter MMSE score",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return hasValue(value) || hasValue(form.score_mmse_to);
          },
        },
        {
          message: "MMSE score From must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "MMSE score From must be between 0 and 30",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 0, 30);
          },
        },
      ],
      score_mmse_to: [
        {
          message: "Please enter MMSE score",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return hasValue(value) || hasValue(form.score_mmse_from);
          },
        },
        {
          message: "MMSE score To must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "MMSE score To must be between 0 and 30",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 0, 30);
          },
        },
        {
          message: TO_BIGGER_THAN_FROM_MESSAGE,
          trigger: "blur",
          validator: (rule: any, value: string) => {
            if (
              value &&
              form.score_mmse_from &&
              checkIsNumber(form.score_mmse_from)
            ) {
              return parseInt(value, 10) >= form.score_mmse_from;
            } else {
              return true;
            }
          },
        },
      ],
      score_adascog_from: [
        {
          message: "Please enter ADAScog score",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return hasValue(value) || hasValue(form.score_adascog_to);
          },
        },
        {
          message: "ADAScog score From must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "ADAScog score From must be between 0 and 70",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 0, 70);
          },
        },
      ],
      score_adascog_to: [
        {
          message: "Please enter ADAScog score",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return hasValue(value) || hasValue(form.score_adascog_from);
          },
        },
        {
          message: "ADAScog score To must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "ADAScog score To must be between 0 and 70",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 0, 70);
          },
        },
        {
          message: TO_BIGGER_THAN_FROM_MESSAGE,
          trigger: "blur",
          validator: (rule: any, value: string) => {
            if (
              value &&
              form.score_adascog_from &&
              checkIsNumber(form.score_adascog_from)
            ) {
              return parseInt(value, 10) >= form.score_adascog_from;
            } else {
              return true;
            }
          },
        },
      ],
      operator_type: [
        {
          message: "Please enter operator",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            const needToFillOperator = !!(
              (form.score_mmse_from || form.score_mmse_to) &&
              (form.score_adascog_from || form.score_adascog_to)
            );

            return needToFillOperator && !value ? false : true;
          },
        },
        {
          message: "Please remove operator",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            const notNeedToFillOperator = !(
              (form.score_mmse_from || form.score_mmse_to) &&
              (form.score_adascog_from || form.score_adascog_to)
            );

            return notNeedToFillOperator && value ? false : true;
          },
        },
      ],
      age_from: [
        {
          message: "Age From must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "Age From must be between 18 and 120",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 18, 120);
          },
        },
      ],
      age_to: [
        {
          message: "Age To must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "Age To must be between 18 and 120",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 18, 120);
          },
        },
        {
          message: TO_BIGGER_THAN_FROM_MESSAGE,
          trigger: "blur",
          validator: (rule: any, value: string) => {
            if (value && form.age_from && checkIsNumber(form.age_from)) {
              return parseInt(value, 10) >= form.age_from;
            } else {
              return true;
            }
          },
        },
      ],
    });

    const state = reactive({
      isSubmitted: false,
      loading: false,
    });

    watch(
      () => [form.age_from, form.age_to],
      (newValue: any, oldValue: any) => {
        if (
          checkIsNumber(newValue[0], true) &&
          checkIsNumber(newValue[1], true)
        ) {
          form.age = newValue;
        }
      }
    );

    const presetForm = ref<any>(null);
    return {
      state,
      form,
      rules,
      isEdit,
      presetForm,
      ...useFormUtils(form),
      isRequiredFilled: computed(
        () =>
          !!(
            form.name &&
            (form.score_adascog_from || form.score_adascog_to) &&
            (form.score_mmse_from || form.score_mmse_to)
          )
      ),
      setAge: (age: [number, number]) => {
        form.age_from = age[0];
        form.age_to = age[1];
      },
      submitForm() {
        state.loading = true;
        presetForm.value.validate(async (valid: boolean) => {
          if (valid) {
            const model = {
              ...form,
            };

            if (model.score_mmse_from || model.score_mmse_to) {
              if (!model.score_mmse_from) model.score_mmse_from = 0;
              if (!model.score_mmse_to) model.score_mmse_to = 30;
            }

            if (model.score_adascog_from || model.score_adascog_to) {
              if (!model.score_adascog_from) model.score_adascog_from = 0;
              if (!model.score_adascog_to) model.score_adascog_to = 70;
            }

            try {
              if (isEdit.value) {
                await editPreset(props.siteId as string, model as PresetItem);
              } else {
                await createPreset(props.siteId as string, model as PresetItem);
              }

              state.isSubmitted = true;

              emit("updated", model);
            } catch (ex) {
              console.log(ex);
              showErrorOptional(ex.title, ex);
            } finally {
              state.loading = false;
            }
          }
          state.loading = false;
        });
      },
      cancel() {
        emit("cancel");
      },
    };
  },
});
