export function hasValue(value: any) {
  return value !== null && value !== undefined && value !== "";
}

const NUMBER_REGEX = /^[0-9]+[.]{0,1}[0-9]*$/;
export function checkIsNumber(value: any, checkNull = false) {
  if (hasValue(value)) {
    return NUMBER_REGEX.test(value);
  }
  return checkNull ? false : true;
}

export function checkIsBetween(value: any, min: any, max: any) {
  if (value && checkIsNumber(value)) {
    return min <= value && value <= max;
  }
  return true;
}
