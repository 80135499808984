export const AZ_MESSAGE =
  "Only A-Z characters and special symbols are possible";

export const FIRST_NAME_MESSAGE = "Please enter first name of the user";
export const LAST_NAME_MESSAGE = "Please enter last name of the user";
export const EMAIL_REQUIRED_MESSAGE = "Please enter email of the user";
export const EMAIL_CORRECT_MESSAGE = "Please enter correct user email";

export const CORRECT_PHONE_CODE_MESSAGE = "Please enter correct phone code";
export const CORRECT_PHONE_NUMBER_MESSAGE = "Please enter correct phone number";
export const USER_WITH_SAME_EMAIL_MESSAGE =
  "User with such email already exists in the system.";

// Sites
export const SITE_NAME_REQUIRED_MESSAGE = "Please enter name of the site";
export const COUNTRY_REQUIRED_MESSAGE = "Please select a country for this site";
export const DATA_STORAGE_REQUIRED_MESSAGE =
  "Please select a data source for this site";

export const WEBSITE_FORMAT_MESSAGE = `Website is in wrong format`;

export const PRESET_NAME_REQUIRED_MESSAGE = "Please enter name of the preset";
export const TO_BIGGER_THAN_FROM_MESSAGE =
  'Value in the field "To" must not be less than value in the field "From"';

export const SUBJECT_ID_REQUIRED_MESSAGE = "Please enter subject ID";
export const SEX_REQUIRED_MESSAGE = "Please choose subject sex";
export const YEAR_OF_BIRTH_REQUIRED_MESSAGE = "Please enter year of birth";
export const YEAR_OF_BIRTH_RANGE_MESSAGE = (start: number, end: number) =>
  `Year of birth must be between ${start} and ${end}`;
