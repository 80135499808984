import { RangeFilterItem } from "./../RangeFilter/RangeFilter";
import { computed, defineComponent, reactive, ref } from "vue";

import ChevronDownSmallIcon from "@/assets/icons/ChevronDownSmall.vue";
import FilterIcon from "@/assets/icons/Filter.vue";

import HeaderFilter from "@/components/DropdownFilter/DropdownFilter.vue";
import RangeFilter from "@/components/RangeFilter/RangeFilter.vue";
import {
  FilterItem,
  FilterObject,
} from "@/components/DropdownFilter/DropdownFilter";
import { SortingDirection } from "@/interfaces/SortingItem";
import { hasValue } from "@/utils/validationUtils";

export default defineComponent({
  name: "TableHeaderComponent",
  props: {
    header: String,
    sortable: { type: Boolean, default: false },
    sortingValue: { type: String as () => SortingDirection, default: null },
    filterable: { type: Boolean, default: false },
    filterData: { type: Object as () => FilterObject },
    rangeData: { type: Object as () => RangeFilterItem },
  },
  components: { ChevronDownSmallIcon, FilterIcon, HeaderFilter, RangeFilter },
  setup(props, { emit }) {
    const dropdown = ref(null);
    return {
      dropdown,
      isFilterFilled: computed(
        () =>
          props.filterData?.values.length ||
          (hasValue(props.rangeData?.from) && hasValue(props.rangeData?.to)) ||
          0
      ),
      sortingActive: computed(() => props.sortingValue !== null),
      sortingDesc: computed(() => props.sortingValue === SortingDirection.DESC),
      changeSorting: () => {
        switch (props.sortingValue) {
          case null:
            emit("changeSorting", SortingDirection.ASC);
            break;
          case SortingDirection.ASC:
            emit("changeSorting", SortingDirection.DESC);
            break;
          case SortingDirection.DESC:
            emit("changeSorting", null);
            break;
        }
      },
      closeDropdown() {
        if (dropdown.value) {
          (dropdown.value as any).visible = false;
        }
      },
    };
  },
});
