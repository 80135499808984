import useDateFormatter from "@/uses/useDateFormatter";
import useFormUtils from "@/uses/useFormUtils";
import { TO_BIGGER_THAN_FROM_MESSAGE } from "@/utils/validationMessages";
import { checkIsBetween, checkIsNumber } from "@/utils/validationUtils";
import { computed, defineComponent, reactive, ref, watch } from "vue";

export interface RangeFilterItem {
  from: any;
  to: any;
  isYear: boolean;
  min: number;
  max: number;
}

export default defineComponent({
  name: "RangeFilter",
  props: {
    filterData: { type: Object as () => RangeFilterItem, required: true },
  },
  components: {},
  setup(props, { emit }) {
    const form = reactive({
      from: props.filterData.from,
      to: props.filterData.to,
    });

    const { middleDate } = useDateFormatter();

    const rules = reactive({
      from: [
        {
          message: "From must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            if (props.filterData.isYear) return true;
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: `From must be between ${props.filterData.min} and ${props.filterData.max}`,
          validator: (rule: any, value: any) => {
            if (
              props.filterData.min !== undefined &&
              props.filterData.max !== undefined
            ) {
              return checkIsBetween(
                props.filterData.isYear
                  ? (value as Date)?.getFullYear()
                  : value,
                props.filterData.min,
                props.filterData.max
              );
            }
            return true;
          },
        },
        {
          message: TO_BIGGER_THAN_FROM_MESSAGE,
          trigger: "blur",
          validator: (rule: any, value: string) => {
            if (value && form.to) {
              if (props.filterData.isYear) {
                return value <= form.to;
              }
              return parseInt(value, 10) <= form.to;
            } else {
              return true;
            }
          },
        },
      ],
      to: [
        {
          message: "To must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            if (props.filterData.isYear) return true;
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: `To must be between ${props.filterData.min} and ${props.filterData.max}`,
          validator: (rule: any, value: any) => {
            if (
              props.filterData.min !== undefined &&
              props.filterData.max !== undefined
            ) {
              return checkIsBetween(
                props.filterData.isYear
                  ? (value as Date)?.getFullYear()
                  : value,
                props.filterData.min,
                props.filterData.max
              );
            }
            return true;
          },
        },
      ],
    });

    const rangeFilterForm = ref<any>(null);

    return {
      rules,
      form,
      rangeFilterForm,
      middleDate,
      ...useFormUtils(form),
      submitForm() {
        rangeFilterForm.value.validate(async (valid: boolean) => {
          if (valid) {
            let { from, to } = form;
            if (props.filterData.isYear) {
              from = (from as Date)?.getFullYear() || "";
              to = (to as Date)?.getFullYear() || "";
            }

            // eslint-disable-next-line vue/no-mutating-props
            props.filterData.from = from;
            // eslint-disable-next-line vue/no-mutating-props
            props.filterData.to = to;

            emit("closeDropdown");
          }
        });
      },
      cancel() {
        form.from = "";
        form.to = "";
        // eslint-disable-next-line vue/no-mutating-props
        props.filterData.from = form.from;
        // eslint-disable-next-line vue/no-mutating-props
        props.filterData.to = form.to;

        // emit("closeDropdown");
      },
    };
  },
});
