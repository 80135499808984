<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="rv3twxoboa"
        d="M7.06 0.393L4 3.447 0.94 0.393 0 1.333 4 5.333 8 1.333z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M16 16L0 16 0 0 16 0z"
                      opacity=".87"
                      transform="translate(-815 -414) translate(105 272) translate(32 62) translate(0 64) translate(616 4) translate(16 12) translate(46)"
                    />
                    <g
                      transform="translate(-815 -414) translate(105 272) translate(32 62) translate(0 64) translate(616 4) translate(16 12) translate(46) translate(4 5.333)"
                    >
                      <mask id="djyx2borlb" fill="#fff">
                        <use xlink:href="#rv3twxoboa" />
                      </mask>
                      <use
                        fill="#000"
                        fill-rule="nonzero"
                        xlink:href="#rv3twxoboa"
                      />
                      <g fill="#90A4AE" mask="url(#djyx2borlb)">
                        <path
                          d="M0 0H16V16H0z"
                          transform="translate(-4 -5.333)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
