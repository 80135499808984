<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="l1puah7mja"
        d="M2 1.641h6.667l-3.34 3.877L2 1.64zm-1.833-.24C1.513 2.995 4 5.949 4 5.949V9.64c0 .338.3.615.667.615H6c.367 0 .667-.277.667-.615V5.949s2.48-2.954 3.826-4.548c.34-.406.027-.99-.526-.99H.693C.14.41-.173.994.167 1.4z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M0 .615h16m0 14.77H0"
                      transform="translate(-412 -414) translate(105 272) translate(32 62) translate(0 64) translate(209 4) translate(16 12) translate(50)"
                    />
                    <g
                      transform="translate(-412 -414) translate(105 272) translate(32 62) translate(0 64) translate(209 4) translate(16 12) translate(50) translate(2.667 2.667)"
                    >
                      <mask id="u5ffkwme6b" fill="#fff">
                        <use xlink:href="#l1puah7mja" />
                      </mask>
                      <use
                        fill="#000"
                        fill-rule="nonzero"
                        xlink:href="#l1puah7mja"
                      />
                      <g fill="#90A4AE" mask="url(#u5ffkwme6b)">
                        <path
                          d="M0 0H16V16H0z"
                          transform="translate(-2.667 -2.667)"
                        />
                      </g>
                    </g>
                    <path
                      d="M0 0.615L16 0.615 16 15.385 0 15.385z"
                      transform="translate(-412 -414) translate(105 272) translate(32 62) translate(0 64) translate(209 4) translate(16 12) translate(50)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
