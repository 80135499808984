import isArray from "lodash-es/isArray";
import { Sex } from "@/api/SitePresetsApi";

export default function useSexFormatter() {
  return {
    formatSex(sex: Sex[] | Sex) {
      const sexArray: Sex[] = isArray(sex) ? (sex as Sex[]) : [sex as Sex];

      return sex
        ? sexArray.map((s) => (s === "M" ? "Male" : "Female")).join(", ")
        : "";
    },
  };
}
