import { computed, defineComponent, onBeforeMount, reactive, ref } from "vue";

import { SiteProfile } from "@/api/interfaces/SitesInterfaces";
import {
  deletePreset,
  PresetItem,
  searchPresets,
  Sex,
} from "@/api/SitePresetsApi";
import { SortingDirection, SortingItem } from "@/interfaces/SortingItem";
import { showError, showErrorOptional } from "@/utils/notificationUtils";

import PresetForm from "./PresetForm/PresetForm.vue";
import TableHeaderComponent from "@/components/TableHeaderComponent/TableHeaderComponent.vue";
import useRangeFormat from "@/uses/useRangeFormatter";
import useSexFormatter from "@/uses/useSexFormatter";

export default defineComponent({
  name: "SiteStudies",
  props: { site: Object as () => SiteProfile },
  components: { PresetForm, TableHeaderComponent },
  setup(props) {
    const siteId = props.site?.id || "";

    const fieldNames = {
      id: "id",
      name: "name",
      score_mmse_from: "score_mmse_from",
      score_mmse_to: "score_mmse_to",
      score_adascog_from: "score_adascog_from",
      score_adascog_to: "score_adascog_to",
      operator_type: "operator_type",
      age_from: "age_from",
      age_to: "age_to",
      sex_types: "sex_types",
    };

    const list = ref<{ data: PresetItem[] }>({
      data: [],
    });

    const state = reactive({
      paging: {
        page: 1,
        pageSize: 5,
        total: 0,
      },
      sorting: null as SortingItem[] | null,
      isLoading: false,
      dialogVisible: false,
      currentPreset: null as PresetItem | null,
    });

    const updatePresets = async (clearPaging = false) => {
      if (clearPaging) {
        state.paging.page = 1;
      }

      state.isLoading = true;
      try {
        const presets = await searchPresets(
          {
            paging: {
              page_number: state.paging.page,
              amount_per_page: state.paging.pageSize,
            },
            sorting: state.sorting ? state.sorting : undefined,
          },
          siteId
        );
        state.paging.total = presets.total;
        list.value.data = presets.entities;
      } catch (ex) {
        showErrorOptional("Error while updating users", ex);
        console.log(ex);
      } finally {
        state.isLoading = false;
      }
    };

    onBeforeMount(() => {
      updatePresets();
    });

    return {
      state,
      list,
      fieldNames,
      siteId,
      ...useRangeFormat(),
      ...useSexFormatter(),
      getSortingValueForField: computed(() => (field: string) => {
        if (state.sorting) {
          const sorting = state.sorting[0];
          return sorting.field === field ? sorting.direction : null;
        }
        return null;
      }),
      popupTitle: computed(() =>
        state.currentPreset ? `Edit ${state.currentPreset.name}` : "Add new preset"
      ),

      addPreset: () => {
        // router.push(`/users/add-user`);
        console.log("add preset");

        state.currentPreset = null;

        state.dialogVisible = true;
      },
      editPreset: (preset: PresetItem) => {
        // router.push(`/users/add-user`);
        console.log("edit preset", preset);

        state.currentPreset = preset;

        state.dialogVisible = true;
      },
      deletePreset: async (preset: PresetItem) => {
        await deletePreset(siteId, preset);
        await updatePresets();
      },
      pageChange: (page: number) => {
        state.paging.page = page;
        updatePresets();
      },
      sortingChange(field: string, direction: SortingDirection | null) {
        console.log("sortingChange", field);
        if (direction === null) {
          state.sorting = null;
        } else {
          state.sorting = [{ field, direction }];
        }
        updatePresets(true);
      },
      modelUpdated() {
        state.currentPreset = null;
        state.dialogVisible = false;
        updatePresets();
      },
      cancel() {
        state.currentPreset = null;
        state.dialogVisible = false;
      },
    };
  },
});
